<template>
	<div class="max-box">
		<div class="header-box">
			<div class="header-left">
				<span>日报表</span>
			</div>
			<div>
                <a-icon
                    type="vertical-align-bottom"
                    title="导出"
                    @click="down"
                />
            </div>
		</div>
		<div class="content-box" style="padding: 10px">
            <div style="display: flex;align-items: center;margin-bottom: 10px">
                <!--搜索查询-->
                <a-date-picker v-model="date" format="YYYY-MM-DD" @change="changeDate" valueFormat="YYYY-MM-DD" :allowClear="false" />
                <a-button type="primary" size="small" @click="getDataList" style="margin-left: 10px">查询</a-button>
            </div>
            <div class="table-container" style="position: relative; overflow: hidden">
                <a-table :dataSource="tableData" :columns="columns" :pagination="false" :loading="tableLoading" :rowKey="(record, index) => index" :scroll="{ y: yHeight }"/>
                <a-pagination
                    v-model="pagination.page"
                    :total="total"
                    :page-size="pagination.pageSize"
                    show-size-changer
                    :show-total="(total) => `共${total}条`"
                    show-less-items
                    :page-size-options="['10', '20']"
                    @change="onChange"
                    @showSizeChange="onChange"
                    size="small"
                />
            </div>
		</div>
	</div>
</template>
<script>

import {downloadDay, exportDown, queryReportDay} from "@/api/report";
import moment from "moment";

export default {
	data() {
		return {
            tableLoading: true, // 表格加载loading
            yHeight:
                document.documentElement.clientHeight -
                document.documentElement.clientHeight / 3,
            pagination: {
                page: 1,
                pageSize: 10,
            },
            date: moment().subtract(1,'day').format("YYYY-MM-DD"),
            tableData: [],
            columns: [
                {
                    title: "序号",
                    dataIndex: "index",
                    width: 80,
                    customRender: (text, record, index) => {
                        return index + 1;
                    },
                    fixed: "left",
                    align: "center",
                },
                {
                    title: "电站名称",
                    dataIndex: "stationName",
                    align: "center",
                },
                {
                    title: "逆变器名称",
                    dataIndex: "deviceTitle",
                    align: "center",
                },
                {
                    title: "逆变器SN",
                    dataIndex: "deviceSn",
                    align: "center",
                },
                {
                    title: "当日发电量",
                    dataIndex: "yesterdayPower",
                    align: "center",
                },
                {
                    title: "总发电量",
                    dataIndex: "sum",
                    align: "center",
                },
                {
                    title: "功率",
                    dataIndex: "power",
                    align: "center",
                },
            ],
            total: 0,
		};
	},
	methods: {
        getDataList(){
            this.tableLoading = true
            queryReportDay({createTime: this.date, page: this.pagination.page, limit: this.pagination.pageSize}).then(res=>{
                console.log(res)
                this.tableData = res.data
                this.total = res.count
                this.pagination.pageSize = res.pageSize
                this.tableLoading = false
            })
        },
        onChange(page, pageSize) {
            console.log(page, pageSize);
            this.pagination.page = page
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        changeDate(e) {
            this.date = moment(e._d).format("YYYY-MM-DD");
        },
        down(){
            downloadDay({createTime: this.date}).then(res=>{
                console.log(res)
                exportDown(res, "日报表");
            })
        }
	},
	mounted() {
        this.getDataList()
	},
	beforeDestroy() {
	},
	computed: {
	},
};
</script>
<style lang="less" scoped>
.max-box {
	width: 100%;
	padding-top: 16px;
	height: 100%;
	padding-right: 20px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	.header-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		// padding-right: 20px;
		margin-bottom: 16px;

		.header-left {
			span {
				font-size: 18px;
				color: #252b3a;
			}

			i {
				font-size: 20px;
			}
		}

		.search-box {
			width: 240px;
			height: 32px;

			/deep/ .ant-input {
				font-size: 12px;
			}
		}

		.add-btn {
			font-size: 12px;
		}
	}

	.content-box {
		background: #fff;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		// tabs
		.tarbar-box {
			padding-bottom: 12px;
			position: relative;
			padding-right: 16px;
			width: 100%;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			display: flex;
			justify-content: space-between;
			align-items: center;

			.tabs {
				display: flex;
				padding-left: 16px;
				align-items: center;
				box-sizing: border-box;

				.tab-one {
					padding: 10px 0 8px 0;
					margin-right: 24px;
					font-size: 12px;
					cursor: pointer;
					color: #515561;
					box-sizing: border-box;
				}

				.open-choose {
					margin-top: 3px;
					cursor: pointer;
					padding-left: 16px;
					border-left: 1px solid rgba(0, 0, 0, 0.1);

					&:hover {
						color: #048fff;
					}

					span {
						margin-right: 1px;
					}

					transition: all 1s;
				}

				.active-choose {
					color: #048fff;

					.icon-down {
						transform: rotate(180deg);
					}
				}

				.active-tab {
					color: #048fff;
					border-bottom: 2px solid #048fff;
				}
			}

			.right-box {
				font-size: 16px;

				i {
					cursor: pointer;

					&:hover {
						color: #048fff;
					}
				}

				.icon-left {
					margin-left: 20px;
					transition: all 0.4s;

					&:hover {
						transform: rotate(90deg);
					}
				}
			}
		}

		.list-box {
			flex-grow: 1;

			.weather-box {
				height: 100%;
				overflow-y: auto;
				display: grid;
				padding: 16px;
				box-sizing: border-box;
				grid-template-columns: repeat(3, 1fr); /* 三列等宽 */
				grid-auto-rows: 60px; /* 自动调整行高度 */
				grid-gap: 10px; /* 列间距和行间距，可根据需要调整 */

				.one-msg {
					font-size: 12px;
					color: #00000073;

					.one-detail {
						color: #515561;
					}
				}
			}
		}

		.name-box {
			cursor: pointer;
			width: 100%;
		}

		// 操作样式
		.operation-box {
			width: 100%;
			padding-left: 15px;
			font-size: 16px;

			i {
				cursor: pointer;
				transition: all 0.5s;

				&:hover {
					color: #048fff;
				}
			}

			.icon-left {
				margin-left: 30px;
			}
		}
	}
}

// 抽屉内容
.add-power {
	transform: translate(0) !important;

	.head-box {
		.name-box {
			display: flex;
			justify-content: space-between;

			span {
				font-size: 18px;
				color: #252b3a;
			}

			.operation-right {
				display: flex;
				align-items: center;
				font-size: 16px;
				color: rgba(0, 0, 0, 0.45);

				.mr_16 {
					margin-right: 16px;
					transition: all 0.5s;
					cursor: pointer;

					&:hover {
						color: #048fff;
					}
				}

				.close-box {
					background: #ff7875;
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 28px;
					height: 28px;
					font-size: 16px;
					color: #fff;
					border-radius: 50%;

					&:hover {
						i {
							transform: rotate(90deg);
						}
					}

					i {
						transition: all 0.3s;
					}
				}
			}
		}

		.status-box {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;

			.left-box {
				display: flex;
				align-items: center;

				i {
					font-size: 12px;
				}

				span {
					font-size: 14px;
					color: #515561;
					margin-left: 6px;
				}
			}

			.right-time {
				font-size: 12px;
				color: #515561;
			}
		}
	}

	.drawer-tab-box {
		padding: 0 8px;
		margin-top: 16px;

		.all-tabs {
			display: flex;

			span {
				padding: 12px 0 8px;
				margin-right: 24px;
				font-size: 12px;
				color: #00000073;
				cursor: pointer;
			}

			.active-drawer-tab {
				color: #048fff;
				border-bottom: 2px solid #048fff;
				font-weight: 700;
			}
		}
	}

	.content-box {
		padding: 0 16px;
		box-sizing: border-box;
	}
}
</style>
