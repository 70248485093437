
import { requestToken } from '@/utils/request.js'

export const queryReportDay = (data) => {
    return requestToken('/InverterAmountStatistics/day', data)
}

export const queryReportMonth = (data) => {
    return requestToken('/InverterAmountStatistics/month', data)
}
export const queryReportYear = (data) => {
    return requestToken('/InverterAmountStatistics/year', data)
}

export const downloadDay = (data) => {
    return requestToken('/InverterAmountStatistics/downDay', data, {
        responseType: 'blob',
    })
}
export const downloadMonth = (data) => {
    return requestToken('/InverterAmountStatistics/downMonth', data, {
        responseType: 'blob',
    })
}
export const downloadYear = (data) => {
    return requestToken('/InverterAmountStatistics/downYear', data, {
        responseType: 'blob',
    })
}

export function exportDown(res, fileName) {
    if (res.size == 0) {
        return this.$message({
            type: 'warning',
            message: '暂无导出数据'
        })
    }
    // type的格式可以可以设置，可以把appcation/json设置进去，然后设置导出的类型
    const blob = new Blob([res], { type: 'application/vnd.ms-excel' })
    // 兼容ie和360浏览器
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //  fileName需要指定后缀类型例：.doc
        window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
        let url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)   // 删除节点
        window.URL.revokeObjectURL(url)  // 释放blob对象
    }
}
